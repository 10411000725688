import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
  return (
    c: AbstractControl<string | number | null>,
  ): ValidationErrors | null => {
    if (!c.value) {
      return null;
    }

    const value = c.value.toString();

    const isPhoneNumber = /^[+?|\d][\d|\W]+$/.test(value);

    if (isPhoneNumber) {
      return null;
    }

    return { phoneNumber: { value } };
  };
}
